// sass-lint:disable force-pseudo-nesting
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/variables';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/tools/mixins';
@import 'styles/tools/mixins';

.backToTop {
  @include no-print;
  cursor: pointer;
  opacity: 0;
  transition: opacity .25s ease-in-out;
  bottom: 0;
  margin: {
    right: 10px;
    bottom: 10px;
  }
  pointer-events: none;
  position: fixed;
  right: 0;

  &:hover {
    text-decoration: none;
  }

  &.active {
    opacity: 1;
    pointer-events: auto;
  }

  @include media-breakpoint-up('md') {
    margin: {
      bottom: 20px;
      right: 100px;
    }
  }

  @include media-breakpoint-up('lg') {
    margin: {
      bottom: 30px;
      right: 100px;
    }
  }
}

.button {
  $size: 40px;

  align-items: center;
  background-color: $colour-waitrose-grey;
  color: $colour-white;
  border: 0;
  display: flex;
  height: $size;
  justify-content: center;
  padding: 0;
  width: $size;

  &:active,
  &:focus,
  &:hover,
  &:active:focus {
    background-color: $colour-earl-grey;
    outline: none;
  }
}

.icon {
  width: 24px;

  path {
    stroke: $colour-white;
  }
}
