@import '@johnlewispartnership/wtr-ingredients/foundations/colours';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/variables';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/tools/mixins';
@import 'styles/settings/variables';
@import 'styles/tools/mixins';

.footer {
  font-family: $font-family-base;
  box-shadow: 0 0 7px 1px $colour-primary-grey--alpha-40;
  position: relative;
  z-index: 2;

  a {
    text-decoration: none;
  }
}

.linksLists {
  display: block;
  padding: $grid-vertical-gutter * 1.625 0 0 0;

  @include media-breakpoint-up('lg') {
    display: none;
  }

  &.large {
    display: none;
    padding: 38px 16px 45px;

    @include media-breakpoint-up('lg') {
      display: flex;
    }
  }
}

.srOnly {
  @include sr-only;
}

.noPrint {
  @include no-print;
}

.mobileSocialLinks {
  @include no-print;
  display: flex;

  @include media-breakpoint-up('lg') {
    display: none;
  }
}

.mobileAppLinks {
  @include no-print;
  display: flex;

  @include media-breakpoint-up('lg') {
    display: none;
  }
}
