@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/variables';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/tools/mixins';
@import 'styles/settings/variables';

.appLinks {
  background: $colour-scallop-grey;
  display: flex;
  flex: {
    direction: row;
  }
  justify-content: center;
  align-items: center;
  padding: $grid-vertical-gutter;

  @include media-breakpoint-up('lg') {
    background: $colour-white;
    border-left: 1px solid $colour-mushroom-grey;
    align-items: flex-start;
    flex: {
      direction: column;
    }
    justify-content: flex-start;
    padding: 0 $grid-vertical-gutter;
    margin-top: 30px;
  }
}

.appsSectionTitle {
  color: $text-color;
  font: {
    family: $font-family-base;
    size: $font-size-very-small;
    weight: normal;
  }
  margin: 0;

  @include media-breakpoint-up('lg') {
    margin-bottom: 10px;
    font: {
      family: $font-family-base;
      size: $font-size-large;
      weight: normal;
    }
  }
}

.appLink {
  color: $colour-earl-grey;
  cursor: pointer;
  margin-left: $ingredients-unit * .5;
  padding: ($grid-vertical-gutter * .25) 0;

  &:active,
  &:focus {
    outline: 0;
  }

  @include media-breakpoint-up('lg') {
    margin: 0;
  }
}

.appLinkText {
  font: {
    family: $font-family-base;
    size: 16px;
    stretch: normal;
    style: normal;
    weight: 200;
  }
  line-height: 1.5;
  letter-spacing: normal;
  display: none;
  margin-left: ($grid-vertical-gutter * .25);

  @include media-breakpoint-up('lg') {
    display: inline;
  }
}

.icon {
  height: 28px;
  width: 97px;

  & svg {
    height: 28px !important;
    width: 97px !important;
  }
}
