@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/variables';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/tools/mixins';
@import 'styles/settings/variables';
@import 'styles/tools/mixins';

$badge-width: 194px;

.badges {
  display: flex;
  flex: {
    direction: column;
    wrap: nowrap;
  }
  margin: {
    left: auto;
    right: auto;
  }
  max-width: map-get($grid-breakpoints, xl);
  width: 100%;

  @include media-breakpoint-up('md') {
    flex-direction: row;
    justify-content: space-between;
    padding: $grid-vertical-gutter;
  }
}

.logoWrapper {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;

  @include media-breakpoint-up('md') {
    width: $badge-width;
  }
}

.logo {
  @include no-print;
  margin: ($grid-vertical-gutter * 1.5) auto $padding-small-vertical;
}

.copyright {
  color: $colour-earl-grey;
  font-size: $font-size-very-small; // ~12px
  margin: ($grid-vertical-gutter * .5) auto ($grid-vertical-gutter * 1.333);
  text-align: center;
}

.mhra {
  @include no-print;
  margin: 0 auto ($grid-vertical-gutter * 2);
  text-align: center;

  @include media-breakpoint-up('md') {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin: 0;
    width: $badge-width;
  }
}

.mhraLabel {
  display: none;
}

.verifyLink {
  &:active,
  &:focus {
    outline: 0;
  }

  img {
    width: 90px;

    @include media-breakpoint-up('md') {
      float: right;
      margin-right: $grid-vertical-gutter * 2;
    }
  }
}
